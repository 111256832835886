import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'
import { injectIntl } from 'react-intl'

import SEO from '../components/seo'

const Container = styled.section`
  max-width: 1000px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blackOverlay};
  flex: none;
  display: flex;
  flex-direction: column;
  padding: 40px 80px 0px;
  margin: auto 0;

  h1 {
    text-align: center;
  }

  ${media.lessThan('medium')`
    padding: 30px;
  `}

  ${media.lessThan('medium')`
    h1 {
      font-size: 1.625rem;
    }
  `}
`

const AboutPage = ({
  data: {
    allPrismicAboutUs: {
      edges: [
        {
          node: { data },
        },
      ],
    },
  },
  intl,
}) => (
  <>
    <SEO title={intl.formatMessage({ id: 'about' })} />
    <Container dangerouslySetInnerHTML={{ __html: data.content.html }} />
  </>
)

export default injectIntl(AboutPage)

export const query = graphql`
  query($locale: String!) {
    allPrismicAboutUs(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            content {
              html
            }
          }
        }
      }
    }
  }
`
